<template>
  <div
    class="auth-wrapper auth-v1 px-2 img-fundo-login"
    style="background-image: url(https://pesapromo.com.br/images/banners/banner.jpg)"
  >
    <div class="auth-inner py-2">

      <!-- Login v1 -->
      <b-card class="corpo mb-0">
        <b-link class="brand-logo">
          <b-img
            :src="require('@/assets/assets/images/logo/pesa.png')"
            width="290"
            height="80"
          />
        </b-link>

        <b-card-title class="titulo mb-1">
          Bem vindo ao PAINEL LEADS! 👋
        </b-card-title>
        <b-card-text class="texto mb-2">
          Faça login com a sua conta
        </b-card-text>

        <!-- form -->
        <validation-observer
          ref="loginForm"
          #default="{invalid}"
        >
          <b-form
            class="auth-login-form mt-2"
            @submit.prevent="login"
          >
            <!-- email -->
            <b-form-group
              label-for="email"
              label="Email"
            >
              <validation-provider
                #default="{ errors }"
                name="E-mail"
                rules="required|email"
              >
                <b-form-input
                  id="email"
                  v-model="userEmail"
                  name="login-email"
                  :state="errors.length > 0 ? false:null"
                  placeholder="nome@pesacat.com"
                  autofocus
                />
              </validation-provider>
            </b-form-group>

            <!-- password -->
            <b-form-group>
              <div class="senha d-flex justify-content-between">
                <label labe-for="password">Senha</label>
              </div>
              <validation-provider
                #default="{ errors }"
                name="Password"
                rules="required"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                    id="password"
                    v-model="password"
                    :type="passwordFieldType"
                    class="form-control-merge"
                    :state="errors.length > 0 ? false:null"
                    name="login-password"
                    placeholder="Senha"
                  />

                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="passwordToggleIcon"
                      @click="togglePasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
              </validation-provider>
            </b-form-group>

            <!-- checkbox -->
            <b-form-group>
              <b-form-checkbox
                id="remember-me"
                v-model="status"
                name="checkbox-1"
                class="lembre"
              >
                Lembrar de mim
              </b-form-checkbox>
            </b-form-group>

            <!-- submit button -->
            <b-button
              variant="primary"
              type="submit"
              block
              class="botao"
              :disabled="invalid"
              style="font-weight: 900; text-transform: uppercase;"
            >
              Entrar
            </b-button>
          </b-form>
        </validation-observer>
      </b-card>
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
// import VuexyLogo from '@core/layouts/components/Logo.vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import useJwt from '@/auth/jwt/useJwt'
import { getHomeRouteForLoggedInUser } from '@/auth/utils'
// eslint-disable-next-line import/extensions
import ToastificationContent from '@core/components/toastification/ToastificationContent'

export default {
  components: {
    // VuexyLogo,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      userEmail: '',
      password: '',
      status: '',
      // validation rules
      required,
      email,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  methods: {
    login() {
      this.$refs.loginForm.validate().then(success => {
        if (success) {
          this.$http.post('auth/login', { email: this.userEmail, password: this.password })
            .then(response => {
              const { userData } = response.data
              localStorage.setItem('timeL', JSON.stringify(new Date().getTime()))
              if (response.data.error) {
                // eslint-disable-next-line no-alert
                alert('error')
                return
              }
              useJwt.setToken(response.data.accessToken)
              useJwt.setRefreshToken(response.data.refreshToken)
              localStorage.setItem('userData', JSON.stringify(userData))
              this.$ability.update(userData.ability)

              // ? This is just for demo purpose as well.
              // ? Because we are showing eCommerce app's cart items count in navbar
              this.$store.commit('app-ecommerce/UPDATE_CART_ITEMS_COUNT', userData.extras.eCommerceCartItemsCount)

              // ? This is just for demo purpose. Don't think CASL is role based in this case, we used role in if condition just for ease
              this.$router.push(getHomeRouteForLoggedInUser(userData.role))
                .then(() => {
                  this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: `Olá ${userData.fullName || userData.username}`,
                      icon: 'CoffeeIcon',
                      variant: 'success',
                      text: 'Seja bem vindo!. Você não possui nenhum aviso',
                    },
                  })
                })
                .catch(error => {
                  this.$refs.loginForm.setErrors(error.response.data.error)
                })
            })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';

.img-fundo-login{
  background-size: cover;
}

.corpo.card{
  background-color: #000000b0;
}
.titulo{
  color: #fec91a;
}
.texto{
  color: #ffffff;
}
.form-group .d-block{
  color: #ffffff;
}
.senha label{
  color: #ffffff;
}
.esqueceu{
  color: #fec91a;
}
.lembre label{
  color: #fec91a;
}
.botao{
  border-color: #fafefe !important;
  background-color: #fec91a !important;
  color: #000 !important;
}
.crie{
  color: #ffffff;
}
.criar-conta{
  color: #fec91a;
}

.form-control:focus {
  border-color: #9b800d !important;
}
.btn-primary:active {
  background-color: #bf9301 !important;
  border-color: #1e191a !important;
}
.btn-primary:hover:not(.disabled):not(:disabled) {
  box-shadow: 0 8px 25px -8px #b18801;
}
.btn-primary:hover:not(.disabled):not(:disabled) {
  box-shadow: 0 8px 25px -8px #bb9822;
}

</style>
